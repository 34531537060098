import {configureStore} from '@reduxjs/toolkit'
import rootReducer from "./rootReducer";
import {productDetailReducer} from "../features/pages/productDetailPage/productDetailPageSlice";


export const store = configureStore({
    reducer: {
        // pages
        root: rootReducer,
        productDetailPage: productDetailReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

