import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiAuthConnect} from "../common/api/auth/apiAuthConnect";
import {PayloadAction} from "@reduxjs/toolkit/dist/createAction";
import {store} from "./store";


export const connect = createAsyncThunk(
    'root/connect',
    async (_) => {
        const response = await apiAuthConnect()
        localStorage.setItem('accessToken', response.data.items.accessToken.access_token)
        localStorage.setItem("refreshToken", response.data.items.accessToken.refresh_token);
    }
)


export interface RootInterface {
    authenticating: boolean,
    language: string,
    auth: boolean,
}


const initialState: RootInterface = {
    authenticating: true,
    language: 'NL',
    auth: true,
}

export const rootSlice = createSlice({
    name: 'root',
    initialState,
    reducers: {
        authToggle: (state) => {
            state.auth = !state.auth
        },
        changeLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(connect.pending, (state) => {
            state.authenticating = true
        })
        builder.addCase(connect.fulfilled, (state) => {
            state.authenticating = false
        })
    },
})

export const {
    authToggle,
    changeLanguage
} = rootSlice.actions


export default rootSlice.reducer
