import data from '../../static/data/stringTranslations.json'

export const Translate = (key: string, language: string) => {

    let string = 'Nv: ' + key

    data.filter(translateString => translateString["key"] === key).forEach(translateString => {
        // @ts-ignore
        string = translateString[language]
    })

    return string

}

export default Translate
