import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {Header} from "../../_components/Header";
import Translate from "../../../static/data/Translate";

export const ProductDetailPageContainer = () => {

    const {language} = useAppSelector((store) => store.root)
    const {item} = useAppSelector((store) => store.productDetailPage)

    function getId(url: string) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return (match && match[2].length === 11)
            ? match[2]
            : null;
    }


    let iframeMarkup = undefined
    if (item && item.model.videoUrl){
        const videoId = getId(item.model.videoUrl);
        iframeMarkup = 'https://www.youtube.com/embed/' + videoId;
    }

    if (item && item.productPage && !!item.productPage.active) {
        return (
            <>
                <Header/>

                <main className="c-product-display">
                    {!!item.model.upload &&
                    <section className="c-product-display__image">
                        <img src={item.model.upload} alt="product image"/>
                    </section>
                    }
                    <section className="c-product-display__header" style={{backgroundColor: item.partner.primaryColorDark}}>
                        {!!item.model.category.name &&
                        <h1>{item.model.category.name}</h1>
                        }
                        {!!item.brandModelLabel &&
                        <small>{item.brandModelLabel}</small>
                        }
                    </section>
                    {!!item.saleable &&
                    <section className="c-product-display__information">
                        <div className="o-container o-container--primary">
                            <h2>{Translate('Deze fiets is te koop!', language)}</h2>
                            {!!item.productPage.saleContent &&
                            <p dangerouslySetInnerHTML={{__html: item.productPage.saleContent}}/>
                            }
                            {!!item.model.bikedealsUrl &&
                            <a href={item.model.bikedealsUrl} target="_blank" className="btn btn--rounded"
                               style={{backgroundColor: item.partner.primaryColorLight}}><span>{Translate('Ga naar bikedeals.nl', language)}</span></a>
                            }
                        </div>
                    </section>
                    }
                    <section className="c-product-display__specs">
                        <div className="o-container o-container--primary">
                            {!!item.model.introduction &&
                            <>
                                <h2>{Translate('Introductie', language)}</h2>
                                <p dangerouslySetInnerHTML={{__html: item.model.introduction}} className="introduction-text"/>
                            </>
                            }
                            {!!iframeMarkup &&
                            <iframe
                                src={iframeMarkup}
                                frameBorder="0"
                                allow="autoplay; encrypted-media"
                                allowFullScreen
                                title="video"
                            />
                            }
                            <h2>{Translate('Informatie', language)}</h2>
                            {!!item.brand.name &&
                            <div className="c-product-display__specs__single">
                                <span>{Translate('Merk', language)}</span>
                                <span>{item.brand.name}</span>
                            </div>
                            }
                            {!!item.model.name &&
                            <div className="c-product-display__specs__single">
                                <span>{Translate('Model', language)}</span>
                                <span>{item.model.name}</span>
                            </div>
                            }
                            {!!item.model.buildYear &&
                            <div className="c-product-display__specs__single">
                                <span>{Translate('Bouwjaar', language)}</span>
                                <span>{item.model.buildYear}</span>
                            </div>
                            }
                            {!!item.model.salePrice &&
                            <div className="c-product-display__specs__single">
                                <span>{Translate('Prijs', language)}</span>
                                <span>€{item.model.salePrice}</span>
                            </div>
                            }
                            {!!item.saleable && !!item.model.bikedealsUrl &&
                            <div className="c-product-display__specs__single">
                                <span>{Translate('Te koop bij', language)}</span>
                                <span><a href={item.model.bikedealsUrl}>www.bikedeals.nl</a></span>
                            </div>
                            }
                        </div>
                    </section>
                    {!!item.saleable && !!item.model.bikedealsUrl &&
                    <section className="c-product-display__cta">
                        <div className="o-container o-container--primary">
                            <a href={item.model.bikedealsUrl} className="btn btn--rounded"
                               style={{backgroundColor: item.partner.primaryColorLight}}><span>{Translate('Ga naar bikedeals.nl', language)}</span></a>
                        </div>
                    </section>
                    }

                </main>

            </>
        )
    } else {
        return null
    }

}

