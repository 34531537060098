import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import type {RootState, AppDispatch} from './store'
import {useLocation} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {AxiosAuthRefreshRequestConfig} from 'axios-auth-refresh';
import {camelizeKeys} from "humps";
import {apiEnum} from "../common/enums/apiEnum";
import {useEffect, useRef} from "react";
import {store} from "./store";
import {authToggle} from "./rootReducer";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppLocation = () => useLocation()


export const useAppAxios = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        "content-type": "application/json"
    },
    responseType: "json"
});

const refreshAuthLogic = (failedRequest: AxiosAuthRefreshRequestConfig) =>
    useAppAxios
        .post(apiEnum.AUTH + '/refresh', {
            refresh_token: localStorage.getItem("refreshToken")
        })
        .then(tokenRefreshResponse => {

            localStorage.setItem("accessToken", tokenRefreshResponse.data.items.accessToken.accessToken);
            localStorage.setItem("refreshToken", tokenRefreshResponse.data.items.accessToken.refreshToken);

            failedRequest.data.response.config.headers["Authorization"] =
                "Bearer " + tokenRefreshResponse.data.items.accessToken.access_token;
            return Promise.resolve();
        })
        .catch(error => {
            console.log("refresh fail");
            localStorage.removeItem("partnerUser");
            store.dispatch(authToggle())

            return Promise.reject(error);
        });

createAuthRefreshInterceptor(useAppAxios, refreshAuthLogic);

// Use interceptor to inject the token to requests
useAppAxios.interceptors.request.use(request => {

    if (request.headers) {

        request.headers['Authorization'] = `Bearer ${getAccessToken()}`;

        /**
         if (request.data) {
            request.data = decamelizeKeys(request.data);
        } */

    }

    return request;
});

useAppAxios.interceptors.response.use((response: AxiosResponse) => {
    response.data = camelizeKeys(response.data);
    return response;
});

function getAccessToken() {
    return localStorage.getItem('accessToken');
}


export const useHasChanged = (val: any) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
}

export const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
