import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "./hooks";
import {connect} from "./rootReducer";
import {ProductDetailPageContainer} from "../features/pages/productDetailPage/ProductDetailPageContainer";
import {loadProductDetail} from "../features/pages/productDetailPage/productDetailPageSlice";


const App = () => {

    const dispatch = useAppDispatch()
    const {language} = useAppSelector((store) => store.root)
    const itemNumber = window.location.pathname.replace('/i/', '');

    useEffect(() => {
        dispatch(connect()).then(() => {
            dispatch(loadProductDetail({id: itemNumber, language: language}))
        })
    }, [dispatch])


    return (
        <ProductDetailPageContainer/>
    )

}

export default App;
