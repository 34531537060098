export const apiEnum = {
    AUTH: process.env.REACT_APP_BASE_URL + '/api/auth',
    ENVIRONMENT: process.env.REACT_APP_BASE_URL + '/api/environment',
    PARTNER: process.env.REACT_APP_BASE_URL + '/api/partner',
    GENERAL: process.env.REACT_APP_BASE_URL + '/api/general',
    CLIENT: process.env.REACT_APP_BASE_URL + '/api/client',
    WEBSHOP: process.env.REACT_APP_BASE_URL + '/api/webshop',
    TASK_USER: process.env.REACT_APP_BASE_URL + '/api/task/user',
    PRODUCT: process.env.REACT_APP_BASE_URL + '/api/productpage'
}

