import {changeLanguage} from "../../app/rootReducer";
import {useEffect} from "react";
import {loadProductDetail} from "../pages/productDetailPage/productDetailPageSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {languageList} from "../../static/data/languageList";

export const Header = () => {

    const dispatch = useAppDispatch()
    const {language} = useAppSelector((store) => store.root)
    const {item, languages} = useAppSelector((store) => store.productDetailPage)
    const itemNumber = window.location.pathname.replace('/i/', '');

    let flagCode = language
    let countryFlagSrc = null

    if (flagCode === 'DE' || flagCode === 'EN' || flagCode === 'ES' || flagCode === 'FR' || flagCode === 'IT' || flagCode === 'NL' || flagCode === 'PT') {
        countryFlagSrc = '/images/' + flagCode + '.png'
    }

    useEffect(() => {
        dispatch(loadProductDetail({id: itemNumber, language: language}))
    }, [])

    useEffect(() => {
        dispatch(loadProductDetail({id: itemNumber, language: language}))
    }, [language])

    if (item && item.productPage && !!item.productPage.active) {
        return (

            <header className="c-header c-header--bike">
                <nav className="c-header__primary">
                    {!!item.partner.upload &&
                    <img className="c-header__logo" src={item.partner.upload} alt=""/>
                    }
                    {!!languages &&
                    <div className="c-lang-selector">
                        {!!countryFlagSrc &&
                        <img src={countryFlagSrc} alt="country flag"/>
                        }
                        <i className="icon icon-arrow-down"/>
                        <select onChange={(e) => dispatch(changeLanguage(e.target.value))}>
                            {languages.map((language, index) => {
                                return (
                                    <option value={language.code} key={index}>{language.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    }
                </nav>
            </header>

        )
    } else {
        return null
    }

}

