import {apiEnum} from "../../enums/apiEnum";
import axios from "axios";

export const apiAuthConnect = async () => {
    return await axios.post(apiEnum.AUTH + '/connect', {
        key: '3b149744-be4e-4278-96fe-41b29f809744',
        secret: 'c9161bde-c339-476e-be92-08208389d3f9'
    }).then(function (response) {
        return response
    }).catch(function (error) {
        return error
    })
}
